import axios from "axios";
export default class SalePageService {
  getSalepageById(compId:string, salepageId:string) {

    return axios.get(`${process.env.VUE_APP_HASURA_REST_ENDPOINT}/salepage?salepageId=${salepageId}`, {
      headers: {
        "content-type": "application/json",
        "x-hasura-admin-secret": process.env.VUE_APP_HASURA_ADMIN_SECRET
      },
    });
  }
}
