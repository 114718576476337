import { createRouter, createWebHistory } from 'vue-router'

import pageCreate from "../views/create.vue";

const routes = [
  {
    path: '/create-order/:compId/:productId/:salepageId/:salepageName',
    component: pageCreate}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
