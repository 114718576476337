import { GappBiz } from "@geeesy/type-interfaces";
import axios from "axios";

const API_Name =`${process.env.VUE_APP_AMPLIFY_API_COMMERCE}/public/order-salepage`;

export default class CreateOrderService {
  createOnlineOrder(body: GappBiz.CreateOrderSalePage, salepageId: string) {
    return axios.post(`${API_Name}/create/${salepageId}`, body);
  }

  // updateStatusOnlineOrder(body: any) {
  //   return axios.put(
  //     `${API_Name}${API_Path}/updatestatus/${body.compId}/${body.orderId}`,
  //     body
  //   );
  // }
}
