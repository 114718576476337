import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8d06cda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vth-addr-container" }
const _hoisted_2 = {
  key: 0,
  class: "vth-addr-label text-gray-400"
}
const _hoisted_3 = { class: "vth-addr-input-container relative" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["onMouseover", "onClick"]
const _hoisted_6 = { class: "vth-addr-box-item-top" }
const _hoisted_7 = { class: "vth-addr-float-right" }
const _hoisted_8 = { class: "vth-addr-box-item-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event)),
        placeholder: _ctx.placeholder,
        class: _normalizeClass({
          'vth-addr-input-size-small': _ctx.size === 'small',
          'vth-addr-input-size-default': _ctx.size === 'default',
          'w-full p-3 rounded-md border inputBorder': _ctx.size === 'medium',
          'vth-addr-input-size-large': _ctx.size === 'large',
        }),
        autocomplete: "disabled",
        ref: "input",
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasFocus = true)),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hasFocus = false)),
        onKeydown: [
          _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.pressArrow('up')), ["up"])),
          _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.pressArrow('down')), ["down"])),
          _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.pressEnter()), ["enter"]))
        ]
      }, null, 42, _hoisted_4), [
        [_vModelText, _ctx.currentValue]
      ]),
      (_ctx.resultsFromSearch.length && _ctx.isOpenListContainer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "dropdown",
            class: "vth-addr-list-container",
            style: _normalizeStyle({ top: _ctx.findListContainerPosition() })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultsFromSearch, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["vth-addr-list", { 'vth-addr-list-on-focused': _ctx.itemOnFocus === index }]),
                style: _normalizeStyle({
            'background-color': _ctx.itemOnFocus === index ? _ctx.currentColor : '#fff',
          }),
                key: index,
                onMouseover: ($event: any) => (_ctx.itemOnFocus = index),
                onMouseout: _cache[6] || (_cache[6] = ($event: any) => (_ctx.itemOnFocus = -1)),
                onClick: ($event: any) => (_ctx.clickSelectItem(item))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["item-first", {
                'vth-addr-box-item-top-focused':
                  _ctx.itemOnFocus === index && _ctx.currentColor !== '#f5f5f5',
              }])
                  }, _toDisplayString(_ctx.itemFirst(item)), 3),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["vth-addr-item-second", {
                  'vth-addr-box-item-top-focused':
                    _ctx.itemOnFocus === index && _ctx.currentColor !== '#f5f5f5',
                }])
                    }, _toDisplayString(_ctx.itemSecond(item)), 3),
                    _createElementVNode("span", {
                      class: _normalizeClass(["vth-addr-item-third", {
                  'vth-addr-box-item-top-focused':
                    _ctx.itemOnFocus === index && _ctx.currentColor !== '#f5f5f5',
                }])
                    }, _toDisplayString(_ctx.itemThird(item)), 3)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: "vth-addr-item-first vth-addr-font-weight-bold",
                    style: _normalizeStyle({
                color:
                  _ctx.itemOnFocus === index && _ctx.currentColor !== '#f5f5f5'
                    ? '#fff'
                    : '#000',
              })
                  }, _toDisplayString(_ctx.itemFourth(item)), 5)
                ])
              ], 46, _hoisted_5))
            }), 128))
          ], 4))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "validator", {}, undefined, true)
    ])
  ])), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}