
import { defineComponent ,PropType} from "vue";
import {IFormSalePage} from "@/core/services/order.service";
// import OrderService from "../core/services/order.service";
// const order = new OrderService();
export default defineComponent({
  name: "compProductInfo",
  props: {
    data: Object as PropType<IFormSalePage | null>,
  },
  mounted() {
    this.data?.products.forEach((element) => {
      this.calPrice(element);
    });
  },
  updated() {
    this.data?.products.forEach((element) => {
      this.calPrice(element);
    });
  },
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      formProduct: this.data,
      prefixUrlImage: `https://${process.env.VUE_APP_S3_BLOB_BUCKET}.s3.ap-southeast-1.amazonaws.com/`,
    };
  },
  methods: {
    //สินค้าคงเหลือ
    stock(product) {
      console.log("stock");
      return product.warehouse.stock - product.warehouse.reservedStock;
      // สินค้าที่มีอยู่ใน stock - สินค้าที่ถูกจอง
    },
    currency(e) {
      return parseFloat(e)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortedArray(arrays, idName) {
      return arrays.sort((a, b) => a[idName] - b[idName]);
    },
    plusCount(product) {
      product.productQty = +product.productQty + 1;
      // useInventory = สินค้านับสต็อก
      // hasKeepSelling = สินค้าติดลบได้

      if (
        product.useInventory &&
        product.warehouse &&
        !product.warehouse.hasKeepSelling
      ) {
        // ถ้าสินค้านับสต็อก = true และ ติดลบไม่ได้
        if (product.productQty > this.stock(product)) {
          // ถ้าจำนวนสินค้า มากกว่า จำนวน stock จะ set ให้ productQty = จำนวนใน stock
          product.productQty = this.stock(product);
        }
      }

      this.calPrice(product);
    },
    deleteCount(data) {
      const product = data;
      if (product.productQty > 1) {
        product.productQty = +product.productQty - 1;
        this.calPrice(data);
      }
    },
    chkMin(data) {
      const product = data;
      if (product.productQty < 1) {
        //ขั้นต่ำ 1 ชิ้น
        product.productQty = 1;
      }
    },
    calPrice(data) {
      console.log({ data });

      console.log("calPrice");
      let sumPrice = 0;
      this.data?.products.forEach((element) => {
        console.log({ element });
        const product = element;
        // useInventory = สินค้านับสต็อก
        // hasKeepSelling = สินค้าติดลบได้
        if (
          product.useInventory &&
          element.warehouse &&
          !element.warehouse.hasKeepSelling
        ) {
          // ถ้าสินค้านับสต็อก = true และ ติดลบไม่ได้
          if (product.productQty > this.stock(element)) {
            // ถ้าจำนวนสินค้า มากกว่า จำนวน stock จะ set ให้ productQty = จำนวนใน stock
            product.productQty = this.stock(element);
          }
        }

        if (product.useInventory && product.countStock) {
          const calStock = +product.stockLevel - +product.stockOrder;
          if (+product.productQty > calStock) {
            product.productQty = calStock;
          }
        }
        product.itemsPriceRow = +product.productQty * product.productUnitPrice;
        sumPrice += +product.productQty * product.productUnitPrice;
      });
      console.log({ sumPrice });

      if(this.formProduct){
        this.formProduct.accountingData = {
          discount: 0,
          shippingCost: this.formProduct?.accountingData.shippingCost,
          vat: 0,
          charges: 0,
          grandTotalAmount: sumPrice,
          isDiscountOnPercent: false,
          isVatInc: this.formProduct?.accountingData.isVatInc,
          netAmount: 0,
          subtotalAmount: sumPrice,
          taxWithheldPercent: 0,
          totalDiscountAmount: 0,
          totalPayAmount: 0,
          vatPercentage: this.formProduct?.accountingData.vatPercentage,
          withholdingTax: 0
        };
        this.$emit("click-send");
      }

    },
  },
});
