
import {
  searchAddressByDistrict,
  searchAddressByAmphoe,
  searchAddressByProvince,
  searchAddressByZipcode,
} from "thai-address-database";
import vClickOutside from "v-click-outside";
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueThailandAddressAutocomplete",
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    color: {
      type: String,
    },
    size: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      currentValue: this.value as any,
      currentColor: this.color || "#f5f5f5",
      itemOnFocus: 0,
      isOpenListContainer: false,
      hasFocus: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    resultsFromSearch() {
      let data;
      if (this.type) {
        if (this.type === "district") data = this.resultsFromSearchByDistrict;
        else if (this.type === "amphoe") data = this.resultsFromSearchByAmphoe;
        else if (this.type === "province")
          data = this.resultsFromSearchByProvince;
        else if (this.type === "zipcode")
          data = this.resultsFromSearchByZipcode;
      } else {
        this._errorLog("type is undefined.");
        data = [];
      }
      return data;
    },
    resultsFromSearchByDistrict() {
      return searchAddressByDistrict(this.currentValue || "");
    },
    resultsFromSearchByAmphoe() {
      return searchAddressByAmphoe(this.currentValue || "");
    },
    resultsFromSearchByProvince() {
      return searchAddressByProvince(this.currentValue || "");
    },
    resultsFromSearchByZipcode() {
      return searchAddressByZipcode(this.currentValue || "");
    },
  },
  watch: {
    /**
     * - Update v-model of user.
     * - Set ให้ List Container แสดงเมื่อ currentValue มีการเปลี่ยนแปลง
     * - Set itemOnFocus = first item.
     */
    currentValue(value) {
      console.log(value);
      this.$emit("input", this.currentValue);
      if (this.hasFocus) {
        this.isOpenListContainer = true;
      }
      this.itemOnFocus = 0;
    },
    /**
     * - ถ้า v-model เปลี่ยนค่าจากนอก Component
     * - ให้เปลี่ยนค่า Internal value ด้วย
     * - และซ่อน Dropdown
     */
    value(value) {
      if (value !== this.currentValue) {
        this.currentValue = value;
        this.$nextTick(() => (this.isOpenListContainer = false));
      }
    },
    /**
     * - When color is changed: set internal value.
     */
    color(val) {
      this.currentColor = val;
    },
  },
  methods: {
    onClickOutside(event) {
      console.log(event);
      this.isOpenListContainer = false;
    },
    /**
     * Arrows keys listener.
     */
    pressArrow(direction) {
      if (direction === "up") {
        this.setInputCursorToLastChar();
        this.itemOnFocus = this.itemOnFocus > 0 ? this.itemOnFocus - 1 : 0;
      } else {
        this.itemOnFocus =
          this.itemOnFocus < this.resultsFromSearch.length - 1
            ? this.itemOnFocus + 1
            : this.resultsFromSearch.length - 1;
      }
      this.moveScrollOfListContainer();
    },
    /**
     * Enter button listener.
     */
    pressEnter() {
      this.setSelectedValue(this.resultsFromSearch[this.itemOnFocus]);
    },
    /**
     * เลื่อน scroll bar ตาม Item ที่เลือก
     */
    moveScrollOfListContainer() {
      const list = this.$refs.dropdown as any;
      const element = list.querySelectorAll(".list")[this.itemOnFocus];
      if (!element) return;
      const visMin = list.scrollTop;
      const visMax = list.scrollTop + list.clientHeight - element.clientHeight;
      if (element.offsetTop < visMin) {
        list.scrollTop = element.offsetTop;
      } else if (element.offsetTop >= visMax) {
        list.scrollTop =
          element.offsetTop - list.clientHeight + element.clientHeight;
      }
    },
    /**
     * Event on click item.
     */
    clickSelectItem(address) {
      this.setSelectedValue(address);
    },
    /**
     * - emit ค่าที่เลือกกลับไปยังฟังชั่นของ user
     * - Set currentValue = ข้อมูลที่ user เลือกไว้
     * - ซ่อน List Container
     */
    setSelectedValue(address) {
      this.$emit("select", address);
      this.type
        ? (this.currentValue = address[this.type])
        : this._errorLog("type is undefined.");
      this.$nextTick(() => (this.isOpenListContainer = false));
    },
    /**
     * - หาค่า top ของ Dropwon Container
     */
    findListContainerPosition() {
      let top = "36px";
      if (this.size === "small") {
        top = "27px";
      } else if (this.size === "medium") {
        top = "45px";
      } else if (this.size === "large") {
        top = "54px";
      }
      return top;
    },
    /**
     * - ทำให้ Cursor ของ input อยู่หลังสุดเสมอ
     */
    setInputCursorToLastChar() {
      const len = this.currentValue.length * 2;
      const ref = this.$refs as any;
      setTimeout(() => {
        ref.input.setSelectionRange(len, len);
      }, 1);
    },
    /**
     * - แสดงข้อมูลซ้ายบน
     */
    itemFirst(address) {
      return this.type === "district" ? address["amphoe"] : address["district"];
    },
    /**
     * - แสดงข้อมูลตรงกลางบน
     */
    itemSecond(address) {
      return this.type === "amphoe" || this.type === "district"
        ? address["province"]
        : address["amphoe"];
    },
    /**
     * - แสดงข้อมูลขวาบน
     */
    itemThird(address) {
      return this.type === "province" ||
        this.type === "amphoe" ||
        this.type === "district"
        ? address["zipcode"]
        : address["province"];
    },
    /**
     * - แสดงข้อมูลล่างซ้าย
     */
    itemFourth(address) {
      const type = this.type as any;
      return address[type];
    },
    /**
     * - Error Log
     */
    _errorLog(text) {
      console.error(`[ERROR] vue-thailand-address-autocomplete : ${text}`);
    },
  },
});
