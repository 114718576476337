
// import OrderService from "../core/services/order.service";
import shippingConfirmDialog from "../components/dialogs/shipping-confirmation.vue";
import ThailandAutoComplete from "./vue-thailand-address-autocomplete.vue";
import { defineComponent, PropType } from "vue";
import { GappSetting } from "@geeesy/type-interfaces";
import {
  IShippingMethodTypeBasedByOrderPrice,
  IShippingMethodTypeBasedByPcs,
  IShippingMethodTypeBasedByStepPcs,
  IShippingMethodTypeBasedByStepWeight,
  IShippingMethodTypeFixedRate,
} from "@geeesy/type-interfaces/lib/type-shipping";
import {IFormSalePage} from "@/core/services/order.service";

// const order = new OrderService();
export default defineComponent({
  name: "compShipping",
  components: {
    shippingConfirmDialog,
    ThailandAutoComplete,
  },
  props: {
    data:  Object as PropType<IFormSalePage | null>,
    hasVatRegistration: null,
    orderChannelUTM: null,
    shippingData: Array as PropType<GappSetting.ShippingMethod[]>,
  },
  mounted() {
    this.dataDetail = this.data;

    // มีขนส่งอันเดียว
    if (this.shippingData?.length == 1) {
      this.inpRadShipping = this.shippingData[0].shippingMethodId;
      this.dataSelectShipping = this.shippingData[0];
      this.triggerSelectShipping();
    }
    if (this.data?.hasNoShipment) {
      this.dataDetail.accountingData.shippingCost = 0;
    }

    // console.log(this.dataSelectShipping);
    // console.log(this.inpRadShipping);
  },
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      dataDetail: null as any,
      dataSelectShipping: null as any,
      inpRadShipping: "",
      errors: [] as any,
      breakForeach: false,
      formShipping: {
        firstname: {
          value: "",
          error: "",
        },
        lastname: {
          value: "",
          error: "",
        },
        postalcode: {
          value: "",
          error: "",
        },
        phone: {
          value: "",
          error: "",
        },
        address: {
          value: "",
          error: "",
        },
        province: {
          value: "",
          error: "",
        },
        tambon: {
          value: "",
          error: "",
        },
        amphoe: {
          value: "",
          error: "",
        },
        email: {
          value: "",
          error: "",
        },
      },
    };
  },
  methods: {
    select(address) {
      // ทำงานเมื่อมีการ select ตำบล อำเภอ จังหวัด เพื่อ pathValue ลง form
      if (address.district) {
        this.formShipping.tambon.value = address.district;
      }
      if (address.amphoe) {
        this.formShipping.amphoe.value = address.amphoe;
      }
      if (address.province) {
        this.formShipping.province.value = address.province;
      }
      if (address.zipcode) {
        this.formShipping.postalcode.value = address.zipcode;
      }
      if (address.district) {
        this.checkFromAddress();
      }
    },
    changeAddress(event, from) {
      // ทำงานเมื่อมีการ change ตำบล อำเภอ จังหวัด เพื่อ pathValue ลง form
      console.log({ from });
      from.value = event.target.value;
      console.log({ from });
      this.checkFromAddress();
    },
    checkFromAddress() {
      // check validate form
      if (this.formShipping.tambon.value === "") {
        this.formShipping.tambon.error = "* กรุณากรอกตำบล";
        this.errors.push("* กรุณากรอกตำบล");
      } else {
        this.formShipping.tambon.error = "";
      }

      if (this.formShipping.amphoe.value === "") {
        this.formShipping.amphoe.error = "* กรุณากรอกอำเภอ";
        this.errors.push("* กรุณากรอกอำเภอ");
      } else {
        this.formShipping.amphoe.error = "";
      }

      if (this.formShipping.province.value === "") {
        this.formShipping.province.error = "* กรุณากรอกจังหวัด";
        this.errors.push("* กรุณากรอกจังหวัด");
      } else {
        this.formShipping.province.error = "";
      }

      if (this.formShipping.postalcode.value === "") {
        this.formShipping.postalcode.error = "* กรุณากรอกรหัสไปรษณีย์";
        this.errors.push("* กรุณากรอกรหัสไปรษณีย์");
      } else {
        this.formShipping.postalcode.error = "";
      }
    },
    setData(data) {
      // set text shipping
      if (data.shippingMethodType === "free") {
        // ANCHOR: ส่งฟรี
        return "ฟรีไม่เก็บค่าจัดส่ง";
      } else if (data.shippingMethodType === "fixedRate") {
        // ANCHOR: กำหนดค่าขนส่งคงที่
        return (
          "ค่าจัดส่งคงที่ " +
          (
            Math.round(data.shippingMethodAttribute.shippingCost * 100) / 100
          ).toLocaleString() +
          " บาท"
        );
      } else if (data.shippingMethodType === "basedByPcs") {
        // ANCHOR: คิดตามจำนวนสินค้า (คิดเป็นชิ้น)
        return (
          "ชิ้นแรก " +
          (
            Math.round(
              data.shippingMethodAttribute.shippingCostFirstPcs * 100
            ) / 100
          ).toLocaleString() +
          " บาท" +
          " ถัดไปชิ้นละ " +
          (
            Math.round(data.shippingMethodAttribute.shippingCostPerPcs * 100) /
            100
          ).toLocaleString() +
          " บาท"
        );
      } else if (data.shippingMethodType === "basedByStepPcs") {
        // ANCHOR: คิดตามจำนวนสินค้า
        const shippingCostArr: any = [];

        if (data.shippingMethodAttribute.shippingCost.length) {
          data.shippingMethodAttribute.shippingCost.forEach((el) => {
            shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
          });
        }

        const minShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.min(a, b)
        );
        const maxShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.max(a, b)
        );

        return (
          "คิดตามจำนวนสินค้า " +
          `${
            data.shippingMethodAttribute.minOrderTotalAmount > 0
              ? "0"
              : minShipcost.toLocaleString()
          } - ` +
          maxShipcost.toLocaleString() +
          " บาท"
        );
      } else if (data.shippingMethodType === "basedByStepWeight") {
        // ANCHOR: คิดตามน้ำหนักรวมของสินค้า
        const shippingCostArr: any = [];

        if (data.shippingMethodAttribute.shippingCost.length) {
          data.shippingMethodAttribute.shippingCost.forEach((el) => {
            shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
          });
        }

        const minShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.min(a, b)
        );
        const maxShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.max(a, b)
        );

        return (
          "คิดตามน้ำหนักรวมสินค้า " +
          `${
            data.shippingMethodAttribute.minOrderTotalAmount > 0
              ? "0"
              : minShipcost.toLocaleString()
          } - ` +
          maxShipcost.toLocaleString() +
          " บาท"
        );
      } else if (data.shippingMethodType === "basedByOrderPrice") {
        // ANCHOR: คิดตามราคาของสินค้าหลังหักส่วนลดแล้ว

        const shippingCostArr: any = [];

        if (data.shippingMethodAttribute.shippingCost.length) {
          data.shippingMethodAttribute.shippingCost.forEach((el) => {
            shippingCostArr.push(Math.round(el.shippingCost * 100) / 100);
          });
        }
        const minShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.min(a, b)
        );
        const maxShipcost: number = shippingCostArr.reduce((a, b) =>
          Math.max(a, b)
        );

        return (
          "คิดตามราคาสินค้าหักส่วนลด " +
          minShipcost.toLocaleString() +
          " - " +
          maxShipcost.toLocaleString() +
          " บาท"
        );
      }
    },
    selectShipping(shippingId, itemShipping, datadetail) {
      console.log({ itemShipping });
      this.inpRadShipping = shippingId;
      this.data!.shippingConfirmedData = itemShipping;
      this.dataSelectShipping = itemShipping;
      console.log("dataSelectShipping =>", this.dataSelectShipping);
      console.log("dataDetail =>", datadetail);
      let productQty = 0;
      this.dataDetail.products.forEach((el) => (productQty += +el.productQty)); //ลูปเพื่อหาจำนวนสินค้าทั้งหมด
      if (this.dataSelectShipping.shippingMethodType == "free") {
        //ANCHOR: Type ฟรี ไม่เก็บค่าจัดส่ง
        console.log("Type ฟรี ไม่เก็บค่าจัดส่ง");
        this.dataDetail.accountingData.shippingCost = 0;
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (this.dataSelectShipping.shippingMethodType == "pickup") {
        //ANCHOR: Type ฟรี ไม่เก็บค่าจัดส่ง
        console.log("Type รับที่ร้าน");
        this.dataDetail.accountingData.shippingCost = 0;
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "fixedRate") {
        //ANCHOR: Type กำหนดค่าจัดส่งวัสดุแบบคงที่
        console.log("Type กำหนดค่าจัดส่งวัสดุแบบคงที่");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeFixedRate;
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          this.dataDetail.accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          this.dataDetail.accountingData.shippingCost =
            +dataSelectShipping.shippingCost;
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByPcs") {
        //ANCHOR: Type คิดตามจำนวนสินค้า (คิดเป็นชิ้น)
        console.log("Type คิดตามจำนวนสินค้า (คิดเป็นชิ้น)");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByPcs;
        console.log("calnumberProductsPiece Have freeShipping");
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          this.dataDetail.accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          if (+productQty > 1) {
            // ชิ้นถัดไปราคา...
            this.dataDetail.accountingData.shippingCost =
              (+dataSelectShipping.shippingCostPerPcs *
                100 *
                (+productQty - 1)) /
                100 +
              +dataSelectShipping.shippingCostFirstPcs;
          } else {
            // ชิ้นแรกราคา...
            this.dataDetail.accountingData.shippingCost =
              (+dataSelectShipping.shippingCostFirstPcs * 100 * 1) / 100;
          }
          //shippingCostFirstPcs = ราคาชิ้นแรก
          //shippingCostPerPcs = ราคาชิ้นถัดไป
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByStepPcs") {
        //ANCHOR: Type คิดตามจำนวนสินค้า
        console.log("Type คิดตามจำนวนสินค้า");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByStepPcs;
        this.breakForeach = false;
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          this.dataDetail.accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          const notExceedSort = dataSelectShipping.shippingCost;
          console.log("notExceedSort", notExceedSort);
          if (notExceedSort) {
            notExceedSort.forEach((el, index) => {
              console.log("el", el, index);
              if (!this.breakForeach) {
                if (notExceedSort.length - 1 == index) {
                  // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                  if (+productQty > el.minPcs) {
                    this.dataDetail.accountingData.shippingCost =
                      +el.shippingCost;
                    this.breakForeach = true;
                  }
                } else {
                  if (+productQty <= el.minPcs) {
                    this.dataDetail.accountingData.shippingCost =
                      +el.shippingCost;
                    this.breakForeach = true;
                  }
                }
              }
            });
          }
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByStepWeight") {
        //ANCHOR: Type คิดตามน้ำหนักรวมของสินค้า
        console.log("Type คิดตามน้ำหนักรวมของสินค้า");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByStepWeight;
        console.log({ datadetail });

        let weightCalUnit = 0; //รวมน้ำหนักของ product
        datadetail.products.forEach((product) => {
          weightCalUnit +=
            +product.productPackage.weight * 100 * (+product.productQty / 100);
        });

        console.log("weightCalUnit", weightCalUnit);
        console.log("dataSelectShipping", dataSelectShipping);
        const notExceedSort = dataSelectShipping.shippingCost;
        this.breakForeach = false;
        if (this.chkSpecialConditionsShipping(dataSelectShipping)) {
          // ส่งฟรี
          this.dataDetail.accountingData.shippingCost = 0;
        } else {
          // คิดค่าจัดส่ง
          console.log("notExceedSort", notExceedSort);
          if (notExceedSort) {
            notExceedSort.forEach((el, index) => {
              if (!this.breakForeach) {
                if (notExceedSort.length - 1 == index) {
                  // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                  if (+weightCalUnit > el.minWeight) {
                    this.dataDetail.accountingData.shippingCost =
                      +el.shippingCost;
                    this.breakForeach = true;
                  }
                } else {
                  if (+weightCalUnit <= el.minWeight) {
                    this.dataDetail.accountingData.shippingCost =
                      +el.shippingCost;
                      this.breakForeach = true;
                  }
                }
              }
            });
          }
        }
        console.log("Update After SelectShipping", this.dataDetail);
      } else if (itemShipping.shippingMethodType == "basedByOrderPrice") {
        //ANCHOR: Type คิดตามราคาของสินค้าหลังหักส่วนลดแล้ว
        console.log("Type คิดตามราคาของสินค้าหลังหักส่วนลดแล้ว");
        const dataSelectShipping = this.dataSelectShipping
          .shippingMethodAttribute as IShippingMethodTypeBasedByOrderPrice;
        const notExceedSort = dataSelectShipping.shippingCost;
        console.log("notExceedSort", notExceedSort);
        console.log("dataSelectShipping => ", this.dataSelectShipping);
        this.breakForeach = false;

        if (notExceedSort) {
          notExceedSort.forEach((el, index) => {
            if (!this.breakForeach) {
              if (notExceedSort.length - 1 == index) {
                // ตัวสุดท้ายของ array จะเข้าเงื่อนไขนี้
                if (
                  +this.dataDetail.accountingData.subTotal >
                  el.minOrderTotalAmount
                ) {
                  this.dataDetail.accountingData.shippingCost = +el.shippingCost;
                  this.breakForeach = true;
                }
              } else {
                if (
                  +this.dataDetail.accountingData.subTotal <=
                  el.minOrderTotalAmount
                ) {
                  this.dataDetail.accountingData.shippingCost = +el.shippingCost;
                  this.breakForeach = true;
                }
              }
            }
          });
        }
        console.log("Update After SelectShipping", this.dataDetail);
      }
    },
    chkSpecialConditionsShipping(dataSelectShipping) {
      //เงื่อนไขพิเศษ จัดส่งฟรี เมื่อราคาของสินค้าหลังหักส่วนลดแล้ว มากกว่า minOrderTotalAmount
      return (
        dataSelectShipping.minOrderTotalAmount &&
        +this.dataDetail.accountingData.subtotalAmount >
          +dataSelectShipping.minOrderTotalAmount
      );
    },
    watch(txt) {
      this.errors = [];
      if (txt === "firstname") {
        if (this.formShipping.firstname.value === "") {
          this.formShipping.firstname.error = "* กรุณากรอกชื่อจริง";
          this.errors.push("* กรุณากรอกชื่อจริง");
        } else {
          this.formShipping.firstname.error = "";
        }
      }

      if (txt === "address") {
        if (this.formShipping.address.value === "" && this.validatePickup()) {
          this.formShipping.address.error = "* กรุณากรอกที่อยู่";
          this.errors.push("* กรุณากรอกที่อยู่");
        } else {
          this.formShipping.address.error = "";
        }
      }

      if (txt === "phone") {
        if (this.formShipping.phone.value === "") {
          this.formShipping.phone.error = "* กรุณากรอกเบอร์โทร";
          this.errors.push("* กรุณากรอกเบอร์โทร");
        } else {
          this.formShipping.phone.error = "";
        }
      }

      if (txt === "email") {
        this.formShipping.email.error = "";
        if (
          !this.validEmail(this.formShipping.email.value) &&
          this.formShipping.email.value
        ) {
          this.formShipping.email.error = "* กรุณากรอกอีเมลให้ถูกต้อง";
          this.errors.push("* กรุณากรอกอีเมลให้ถูกต้อง");
        }
      }
    },
    submitForm() {
      console.log("this.shippingData => ", this.shippingData);
      console.log("this.formShipping => ", this.formShipping);
      this.errors = [];
      if (this.formShipping.firstname.value === "") {
        this.formShipping.firstname.error = "* กรุณากรอกชื่อจริง";
        this.errors.push("* กรุณากรอกชื่อจริง");
      } else {
        this.formShipping.firstname.error = "";
      }

      if (this.formShipping.address.value === "" && this.validatePickup()) {
        this.formShipping.address.error = "* กรุณากรอกที่อยู่";
        this.errors.push("* กรุณากรอกที่อยู่");
      } else {
        this.formShipping.address.error = "";
      }

      if (this.formShipping.tambon.value === "" && this.validatePickup()) {
        this.formShipping.tambon.error = "* กรุณากรอกตำบล";
        this.errors.push("* กรุณากรอกตำบล");
      } else {
        this.formShipping.tambon.error = "";
      }

      if (this.formShipping.amphoe.value === "" && this.validatePickup()) {
        this.formShipping.amphoe.error = "* กรุณากรอกอำเภอ";
        this.errors.push("* กรุณากรอกอำเภอ");
      } else {
        this.formShipping.amphoe.error = "";
      }

      if (this.formShipping.province.value === "" && this.validatePickup()) {
        this.formShipping.province.error = "* กรุณากรอกจังหวัด";
        this.errors.push("* กรุณากรอกจังหวัด");
      } else {
        this.formShipping.province.error = "";
      }

      if (this.formShipping.postalcode.value === "" && this.validatePickup()) {
        this.formShipping.postalcode.error = "* กรุณากรอกรหัสไปรษณีย์";
        this.errors.push("* กรุณากรอกรหัสไปรษณีย์");
      } else {
        this.formShipping.postalcode.error = "";
      }

      if (this.formShipping.phone.value === "") {
        this.formShipping.phone.error = "* กรุณากรอกเบอร์โทร";
        this.errors.push("* กรุณากรอกเบอร์โทร");
      } else {
        this.formShipping.phone.error = "";
      }

      this.formShipping.email.error = "";
      if (
        !this.validEmail(this.formShipping.email.value) &&
        this.formShipping.email.value
      ) {
        this.formShipping.email.error = "* กรุณากรอกอีเมลให้ถูกต้อง";
        this.errors.push("* กรุณากรอกอีเมลให้ถูกต้อง");
      }
      console.log("this.errors");
      console.log(this.errors);
      if (!this.errors.length) {
        // ต้องเลือก shipping
        if (this.dataSelectShipping || this.data!.hasNoShipment) {

          this.dataDetail.customerContactInfo.firstName = this.formShipping.firstname.value;
          this.dataDetail.customerContactInfo.lastName = this.formShipping.lastname.value;
          this.dataDetail.customerContactInfo.fullName = this.formShipping.lastname.value ? `${this.formShipping.firstname.value} ${this.formShipping.lastname.value}` : this.formShipping.firstname.value;
          this.dataDetail.customerContactInfo.mobiles = [this.formShipping.phone.value.split("-").join("")];
          this.dataDetail.customerContactInfo.emails = [this.formShipping.email.value];

          this.dataDetail.customerContactInfo.address.addrFull = this.formShipping.address.value
          this.dataDetail.customerContactInfo.address.province = this.formShipping.province.value;
          this.dataDetail.customerContactInfo.address.tambon = this.formShipping.tambon.value;
          this.dataDetail.customerContactInfo.address.amphoe = this.formShipping.amphoe.value;
          this.dataDetail.customerContactInfo.address.postcode = this.formShipping.postalcode.value
          this.dataDetail.customerContactInfo.address.mobiles = [this.formShipping.phone.value.split("-").join("")];
          this.dataDetail.customerContactInfo.address.emails = [this.formShipping.email.value];

          this.dataDetail.customerContactInfo.shippingAddress[0].addrFull = this.formShipping.address.value
          this.dataDetail.customerContactInfo.shippingAddress[0].province = this.formShipping.province.value;
          this.dataDetail.customerContactInfo.shippingAddress[0].tambon = this.formShipping.tambon.value;
          this.dataDetail.customerContactInfo.shippingAddress[0].amphoe = this.formShipping.amphoe.value;
          this.dataDetail.customerContactInfo.shippingAddress[0].postcode = this.formShipping.postalcode.value
          this.dataDetail.customerContactInfo.shippingAddress[0].mobiles = [this.formShipping.phone.value.split("-").join("")];
          this.dataDetail.customerContactInfo.shippingAddress[0].emails = [this.formShipping.email.value];

          this.dataDetail.shipping = [this.dataSelectShipping];
          this.dataDetail.identityId = this.data!.identityId;
          this.dataDetail.identityFullName = this.data!.identityFullName;
          this.dataDetail.companyName = this.data!.companyName;
          this.dataDetail.companyMarketName = this.data!.companyMarketName;
          this.dataDetail.companyMarketLogoUrl = this.data!.companyMarketLogoUrl;
          this.dataDetail.expiryOnPaymentInMin = this.data!.expiryOnPaymentInMin;

          console.log(" this.datathis.datathis.datathis.data => ", this.data);

          console.log("post to api");
          const ref = this.$refs as any;
          console.log(this.dataDetail);
          // เปิด dialog
          ref.shippingDialog.openDialog(
            this.dataDetail,
            this.hasVatRegistration,
            this.orderChannelUTM
          );
        }
      } else {
        // กรอกข้อมูลไม่ครบ scroll ไปยังตำแหน่งของ element นั้นๆ
        var elmnt = document.getElementById("shippingForm") as any;
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
    validatePickup() {
      if (this.dataSelectShipping) {
        return (
          !this.data!.hasNoShipment &&
          this.dataSelectShipping.shippingMethodType !== "pickup"
        );
      } else {
        return !this.data!.hasNoShipment;
      }
    },
    acceptNumber() {
      var x: any = this.formShipping.phone.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.formShipping.phone.value = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
      this.watch("phone");
    },
    triggerSelectShipping() {
      //สั่ง click element นั้น
      console.log("triggerSelectShipping");
      const elementt = document.getElementById(this.inpRadShipping);
      elementt?.click();
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    currency(e) {
      return e;
      // return parseFloat(e)
      //   .toFixed(2)
      //   .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
});
