import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "grid grid-cols-1 xl:grid-cols-4 xl:gap-4 mx-auto mb-70px xl:mb-0",
  style: {"max-width":"1500px","width":"100%"}
}
const _hoisted_3 = {
  key: 0,
  class: "col-span-1 xl:col-span-3"
}
const _hoisted_4 = { class: "block xl:hidden" }
const _hoisted_5 = { class: "row-start-1 xl:row-start-auto col-span-1 order-last" }
const _hoisted_6 = { class: "hidden xl:block" }
const _hoisted_7 = {
  key: 1,
  class: "h-screen flex justify-center items-center"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_compHeader = _resolveComponent("compHeader")!
  const _component_compProductInfo = _resolveComponent("compProductInfo")!
  const _component_compShipping = _resolveComponent("compShipping")!
  const _component_compPriceSummary = _resolveComponent("compPriceSummary")!
  const _component_compBill = _resolveComponent("compBill")!

  return (_ctx.formOrder)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_compHeader, { heading: "คำสั่งซื้อ" }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.salePageDetail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_compProductInfo, {
                  onClickSend: _ctx.triggerShipping,
                  data: _ctx.formOrder
                }, null, 8, ["onClickSend", "data"]),
                _createVNode(_component_compShipping, {
                  ref: "compShipping",
                  shippingData: _ctx.salePageDetail.shippingAvailableData,
                  data: _ctx.formOrder,
                  hasVatRegistration: _ctx.salePageDetail.hasVatRegistration,
                  orderChannelUTM: _ctx.salePageDetail.orderChannelUTM
                }, null, 8, ["shippingData", "data", "hasVatRegistration", "orderChannelUTM"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_compPriceSummary, {
                    onClickSend: _ctx.triggerForm,
                    data: _ctx.formOrder
                  }, null, 8, ["onClickSend", "data"])
                ]),
                _createVNode(_component_compBill, {
                  paymentData: _ctx.salePageDetail.paymentAvailableData,
                  data: _ctx.formOrder
                }, null, 8, ["paymentData", "data"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_compPriceSummary, {
                onClickSend: _ctx.triggerForm,
                data: _ctx.formOrder
              }, null, 8, ["onClickSend", "data"])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        _createElementVNode("img", {
          class: "w-40 h-40",
          src: require('@/assets/images/animation_loading.gif')
        }, null, 8, _hoisted_8)
      ]))
}