
import CreateOrderService from "../../core/services/create-order.service";
import {GappBiz} from "@geeesy/type-interfaces";
import {
  ICompanyTaxBranch,
  IPersonContactInfo,
} from "@geeesy/type-interfaces/lib/type-apps";
import {
  ICompanyContactInfo
} from "@geeesy/type-interfaces/lib/type-company";
import {
  IOrderProductRow,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import {defineComponent} from "vue";
import OrderService from "../../core/services/order.service";

const order = new OrderService();
const _createOrder = new CreateOrderService();
export default defineComponent({
  name: "shippingConfirmDialog",
  data() {
    return {
      saveDisabled: false,
      showModal: false,
      data: Object as any,
      dialogErrorArr: [] as any,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    openDialog(data, hasVatRegistration, orderChannelUTM) {
      //เอาไว้ trigger เพื่อเปิด dialog จากหน้าอื่น
      this.data = {...data, hasVatRegistration, orderChannelUTM}; // เก็บค่าไว้ใช้ใน dialog
      console.log("this.data => ", this.data);
      this.toggleModal();
    },
    async btnOkClick() {
      try {
        this.saveDisabled = true;
        console.log("this.data ------------------>");
        console.log(this.data);
        let productArr: IOrderProductRow[] = [];
        this.data.products.forEach((element) => {
          //ปั้น product เพื่อให้ตรง interface
          console.log({element});
          productArr.push({
            productId: element.productId,
            variantId: element.variantId,
            productTitle: element.productName,
            productUnitPrice: element.productUnitPrice,
            productUnit: element.productUnit,
            productQty: element.productQty,
            productCoverUrl: element.productCoverUrl,
            productWeight: element.productPackage.weight,
            productWeightUnit: element.productPackage.weightUnit,
            productSku: element.productSku,
            discountUnitPrice: element.discountUnitPrice,
            isDiscountOnPercent: false,
            itemsPriceRow: element.itemsPriceRow,
            isVatable: false,
            warehouseId:
                element.useInventory && element.warehouse
                    ? element.warehouse.warehouseId
                    : "",
            inventoryId: element.useInventory && element.warehouse
                ? element.warehouse.inventoryId
                : "",
            productGroupId: element.productGroupId,
            productGroupName: element.productGroupName,
            productCategoryId: element.productCategoryId,
            productCategoryName: element.productCategoryName,
            vatPercentage: element.vatPercentage
            // useInventory: element.useInventory,
          });
        });
        console.log({productArr});

        const query = this.$route.query;
        const keyQuery = Object.keys(this.$route.query);
        const saleChannelSourceUTM: any =
            query[keyQuery[0]] && keyQuery[0] == "utm_source" ? query[keyQuery[0]] : "";
        console.log({query});
        console.log({keyQuery});

        const saleChannelCustomUTM: any = {
          utmName: keyQuery[1] ? keyQuery[1].split("utm_")[1] : "",
          utmValue: query[keyQuery[1]] ? query[keyQuery[1]] : "",
        };

        const salepageName: any = this.$route.params.salepageName;
        const findSalechannel = this.data.orderChannelUTM.find(
            (val) =>
                val.saleChannelSourceUTM == saleChannelSourceUTM &&
                saleChannelCustomUTM.utmName == val.saleChannelCustomUTM.utmName &&
                saleChannelCustomUTM.utmValue == val.saleChannelCustomUTM.utmValue
        );

        let expiredate = new Date()
        expiredate.setTime(expiredate.getTime() + this.data.expiryOnPaymentInMin * 60 * 1000)
        const campaignId = this.$route.query.campaignId as any
        const partnerId = this.$route.query.partnerId as any
        const salepageId: any = this.$route.params.salepageId;

        console.log("query => ", JSON.stringify(query))

        const newData: GappBiz.CreateOrderSalePage = {
          latestEntityOnAction: {
            docNo: '',
            orderId: '',
            salepageId: salepageId,
            orderType: 'SALEPAGE'
          },
          facebookTransactionArr: [0, 0, 0],
          isSendChat: false,
          liveId: '',
          postId: '',
          saleChannelName: '',
          saleChannelImageUrl: '',
          campaignId: campaignId || '',
          partnerId: partnerId || '',
          salepageName: salepageName,
          sellerNote: "",
          products: productArr,
          hasNoShipment: this.data.hasNoShipment,
          accountingData: order.calAccountingData(this.data),
          sellerContactInfo: {
            fullName: "",
            emails: [""],
            mobiles: [""],
            social: order.getSocialGroup(),
            address: order.getAddressGroup(),
            note: "",
            shippingAddress: [order.getAddressGroup()],
          } as IPersonContactInfo,
          sellerCompanyContactInfo: {
            companyMarketName: this.data.companyMarketName,
            companyMarketLogoUrl: this.data.companyMarketLogoUrl,
            companyName: this.data.companyName,
            companyPhones: [""],
            companyWebsite: "",
            companyEmails: [""],
            companySocial: order.getSocialGroup(),
            contactAddress: order.getAddressGroup(),
            billingAddress: order.getAddressGroup(),
            shippingAddress: [order.getAddressGroup()],
            companyNote: "",
            companyRegisNo: "",
            hasVatRegistration: this.data.hasVatRegistration,
            companyTaxInfo: {
              taxNo: "",
              isHq: false,
              branch: [
                {
                  id: "",
                  name: "",
                  address: "",
                  taxNo: "",
                } as ICompanyTaxBranch,
              ],
            },
          } as ICompanyContactInfo,
          totalOrderAmount: +(this.data.accountingData.grandTotalAmount + this.data.accountingData.shippingCost).toFixed(2),
          totalProductAmount: +(this.data.accountingData.subtotalAmount).toFixed(2),
          customerContactInfo: this.data.customerContactInfo,
          customerCompanyContactInfo: {
            companyMarketName: "",
            companyMarketLogoUrl: "",
            companyName: "",
            companyPhones: [""],
            companyWebsite: "",
            companyEmails: [""],
            companySocial: order.getSocialGroup(),
            contactAddress: order.getAddressGroup(),
            billingAddress: order.getAddressGroup(),
            shippingAddress: [order.getAddressGroup()],
            companyNote: "",
            companyRegisNo: "",
            hasVatRegistration: this.data.hasVatRegistration,
            companyTaxInfo: {
              taxNo: "",
              isHq: false,
              branch: [],
            },
          },
          customerNote: "",
          customerId: "",
          saleChannelId: findSalechannel ? findSalechannel.saleChannelId : "",
          saleChannelType: saleChannelSourceUTM,
          expiryTimestamp: this.data.expiryOnPaymentInMin ? Math.floor(expiredate.getTime() / 1000) : 0,
          shippingAvailableIds: this.data.shipping[0]
              ? [this.data.shipping[0].shippingMethodId]
              : [],
          paymentAvailableIds: [""],
          shippingAvailableData: this.data.shipping[0]
              ? this.data.shipping
              : [],
          paymentAvailableData: this.data.paymentAvailableData,
          newSellerStatus: StatusOrderSeller.S0_None,
          orderType: "SALEPAGE",
          toFinalizeStock: false,
          businessId: this.data.businessId,
          compId: this.data.compId,
          identityId: "",
          identityFullName:this.data.customerContactInfo.fullName,
          companyCode: "",
          shippingConfirmedId: this.data.shipping[0]
              ? this.data.shipping[0].shippingMethodId
              : "",
          paymentConfirmedId: "",
          shippingConfirmedData: this.data.shipping[0]
              ? this.data.shipping[0]
              : null,
          paymentConfirmedData: null,
          isNewContactAddress: false,
          paymentAttachmentData: null,
          shippingTrackingData: null,
          saleChannelSourceUTM: saleChannelSourceUTM,
          saleChannelCustomUTM: saleChannelCustomUTM,
          hasOverrideEmailSending: false,
          overrideEmailSendingAttribute: "",
          latestNoteOnAction: "",
          latestActivityOnOrderType: "SALEPAGE",
          // latestActionBy: this.data.identityId,
          // latestActionByFullName: this.data.identityFullName,
          latestActionBy: '',
          latestActionByFullName: '',
        };
        console.log({newData});

        await _createOrder
            .createOnlineOrder(newData, salepageId)
            .then((res: any) => {
              console.log(JSON.stringify(res))
              this.data.link = `https://${order.getEnvName === 'staging' ? '' : `${order.getEnvName}.`}order.gapp-biz.com/salepage/${res.data.shortOrderId}`;
              // this.data.orderId = res.data.data
              // _createOrder.updateStatusOnlineOrder(this.data);
            });
        this.toggleModal();
        location.href = this.data.link;
      } catch (error: any) {
        this.saveDisabled = false;
        console.error("ERROR UPDATE ORDER:" + error);
        // if (error.response.data.error === "Stock is lesser than product qty") {
        const errorArr = this.dialogErrorArr as Array<object>;
        if (error.response.data.data) {
          errorArr.push({
            text: "Not enough stock for reserved ",
            active: true,
          });
        } else {
          errorArr.push({text: error.response.data.message, active: true});
        }
        setTimeout(() => {
          errorArr.shift();
        }, 3000);
        // }
      }
    },
  },
});
