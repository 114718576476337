
import { defineComponent } from 'vue';

export default defineComponent({
  name: "compHeader",
  data() {
    return {};
  },
  props: {
    topic: String,
    heading: String,
    svgIcon: String,
    link: String,
    orderId: String,
  },
});
