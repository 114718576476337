<template>
  <compContainer>
    <router-view />
  </compContainer>
</template>
<script>
import compContainer from "./components/container.component.vue";

export default {
  name: "App",
  components:{
    compContainer
  }
};
</script>