
import compHeader from "../components/header.component.vue";
import compProductInfo from "../components/product-info.component.vue";
import compShipping from "../components/shipping.component.vue";
import compBill from "../components/bill.component.vue";
import compPriceSummary from "../components/price-summary.component.vue";

// import compOrderStatus from "../components/order-status.component";

import ProductService from "../core/services/product.service";
import SalePageService from "../core/services/sale-page.service";
// import ShippingService from "../core/services/shipping.service";
// import PaymentService from "../core/services/payment.service";
import {defineComponent} from "vue";
import {
  IComponentBlockProduct,
  IProductVariantArr,
} from "@geeesy/type-interfaces/lib/type-salepage";
import OrderService from "@/core/services/order.service";
import {IOrderAccounting} from "@geeesy/type-interfaces/lib/type-order"
import {IFormSalePage} from "../core/services/order.service"
import {OfficeHour} from "@geeesy/type-interfaces/lib/type-company";

// const _payment = new PaymentService();
// const _shipping = new ShippingService();
const _product = new ProductService();
const _salepage = new SalePageService();
const _order = new OrderService();

export default defineComponent({
  name: "pageCreate",
  async mounted() {
    const compId: any = this.$route.params.compId;
    const productId: any = this.$route.params.productId;
    const salepageId: any = this.$route.params.salepageId;

    await _salepage
        .getSalepageById(compId, salepageId)
        .then(
            (sale) => (this.salePageDetail = sale.data.salepage_aggregate.nodes[0])
        );

    const product: IComponentBlockProduct =
        this.salePageDetail.salePageComponent.componentBlock.find(
            (res) => res.type === "product"
        ).data;

    await _product
        .getProductDetail(productId)
        .then((response) => (this.productDetail = response.data.data));

    if (!product.isVariant) {
      await _product.getProductStockDetail(compId, productId).then((stock) => {
        const data = stock.data.data.find(
            (val) => val.inventoryId === product.inventorySelect?.inventoryId
        );
        console.log({data});

        this.stockData = {
          ...product,
          warehouse: data,
        };

        this.productDetail = {
          ...this.productDetail,
          productData: this.stockData,
        };
        console.log("this.detail => ", this.productDetail);

        this.productArr.push({
          discountUnitPrice: 0,
          itemsPriceRow:
              +this.productDetail.minQty * +this.productDetail.productData.productUnitPrice,
          productCoverUrl: this.productDetail.productThumbnailUrl,
          productId: this.productDetail.productData.productId,
          variantId: "",
          productQty: this.productDetail.minQty || 1,
          productSku: this.productDetail.productData.productSku,
          productName: this.productDetail.productData.productTitle,
          productUnit: this.productDetail.productData.productUnit,
          productUnitPrice: +product.itemsPriceRow,
          productPackage: this.productDetail.productPackage,
          countStock: this.productDetail.productData.countStock,
          stockLevel: this.productDetail.stockLevel,
          stockOrder: this.productDetail.stockOrder,
          warehouse: this.productDetail.productData.warehouse,
          useInventory: this.productDetail.useInventory,
          productGroupId: this.productDetail.productGroupId,
          productGroupName: this.productDetail.productGroupName,
          productCategoryId: this.productDetail.productCategoryId,
          productCategoryName: this.productDetail.productCategoryName,
        });
      });
    } else {
      this.productDetail = {
        ...this.productDetail,
        productData: product,
      };
      console.log("this.detail => ", this.productDetail);
      const params = this.$route.query.qty as any;
      const newParams = params.split(",");
      console.log({newParams});

      this.productDetail.productData.productVariantArr.forEach(
          (element: IProductVariantArr, index) => {
            _order
                .getProductVariantStock(
                    compId,
                    element.productId,
                    element.productVariantId
                )
                .then((order) => {
                  console.log({order});
                  const countRow = +newParams[index];
                  if (newParams[index] != 0) {
                    this.productArr.push({
                      discountUnitPrice: 0,
                      itemsPriceRow: +countRow * +element.productPrice.retailPrice,
                      productCoverUrl: element.productShowedImageUrl,
                      productId: element.productId,
                      variantId: element.productVariantId,
                      productQty: countRow,
                      productSku: element.productSku,
                      productName: element.variantTitle,
                      productUnit: element.productUnit,
                      productUnitPrice: +element.productPrice.retailPrice,
                      productPackage: this.productDetail.productPackage,
                      countStock: countRow,
                      stockLevel: this.productDetail.stockLevel,
                      stockOrder: this.productDetail.stockOrder,
                      warehouse: {
                        ...order.data.data[0],
                        // ...this.detail.productData.inventorySelect,
                        // warehouseId: "",
                        // stock: element.totalStock,
                        // reservedStock: element.totalReservedStock,
                      },
                      canBelowZero: element.canBelowZero,
                      useInventory: element.useInventory,
                      productGroupId: this.productDetail.productGroupId,
                      productGroupName: this.productDetail.productGroupName,
                      productCategoryId: this.productDetail.productCategoryId,
                      productCategoryName: this.productDetail.productCategoryName,
                    });
                  }
                });
          }
      );
      console.log("this.productArr => ", this.productArr);
    }

    console.log("this.detail");
    console.log(this.productDetail);
    this.buildForm(product);
  },
  data() {
    return {
      productDetail: null as any,
      formOrder: null as (IFormSalePage | null),
      salePageDetail: null as any,
      stockData: null as any,
      productArr: [] as any,
    };
  },

  methods: {
    triggerForm() {
      console.log(this.formOrder);
      console.log("click send shipping");
      const ref = this.$refs as any;
      ref.compShipping.submitForm();
    },
    triggerShipping() {
      const ref = this.$refs as any;
      ref.compShipping.triggerSelectShipping();
    },
    buildForm(product) {
      console.log("create formOrder => ", this.productDetail);
      console.log("create salePageData => ", this.salePageDetail);

      const accountingData: IOrderAccounting = {
        charges: 0,
        discount: 0,
        grandTotalAmount: 0,
        isDiscountOnPercent: false,
        isVatInc: this.salePageDetail.isVatInc,
        netAmount: 0,
        shippingCost: 0,
        subtotalAmount: 0,
        taxWithheldPercent: 0,
        totalDiscountAmount: 0,
        totalPayAmount: 0,
        vat: 0,
        vatPercentage: this.salePageDetail.vatPercentage,
        withholdingTax: 0
      }

      this.formOrder = {
        accountingData,
        expiryOnPaymentInMin: this.salePageDetail.expiryOnPaymentInMin,
        identityId: this.salePageDetail.identityId,
        identityFullName: this.salePageDetail.identityFullName,
        isVariant: product.isVariant,
        compId: this.salePageDetail.compId,
        companyName: this.salePageDetail.companyName,
        companyMarketLogoUrl: this.salePageDetail.companyMarketLogoUrl,
        companyMarketName: this.salePageDetail.companyMarketName,
        businessId: this.salePageDetail.businessId,
        products: this.productArr,
        hasNoShipment: this.salePageDetail.hasNoShipment,
        paymentAvailableData: this.salePageDetail.paymentAvailableData,
        shippingConfirmedData: null,
        customerContactInfo: {
          firstName: "",
          lastName: "",
          fullName: "",
          emails: [],
          mobiles: [],
          social: _order.getSocialGroup(),
          address: {
            addrName: "",
            addrFull: "",
            addrNumber: "",
            province: "",
            amphoe: "",
            tambon: "",
            postcode: "",
            phonesExt: [],
            phones: [],
            mobiles: [],
            faxes: [],
            emails: [],
            country: "",
            lat: "",
            lng: "",
            officeHours: [
              {
                day: "",
                times: ["", ""],
              } as OfficeHour,
            ],
            isPrimary: false,
          },
          note: "",
          shippingAddress: [
            {
              addrName: "",
              addrFull: "",
              addrNumber: "",
              province: "",
              amphoe: "",
              tambon: "",
              postcode: "",
              phonesExt: [],
              phones: [],
              mobiles: [],
              faxes: [],
              emails: [],
              country: "",
              lat: "",
              lng: "",
              officeHours: [
                {
                  day: "",
                  times: ["", ""],
                } as OfficeHour,
              ],
              isPrimary: false,
            },
          ],
          tags: [],
          personPic: "",
          prefixName: ""
        }
      };

      console.log("this.formOrder => ", this.formOrder)
    },
  },
  components: {
    compHeader,
    compProductInfo,
    compShipping,
    compBill,
    compPriceSummary,
  },
});
