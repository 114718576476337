import axios from "axios";

const API_NAME = process.env.VUE_APP_AMPLIFY_API_EX_INVENTORY;
const API_NAME_STOCK =process.env.VUE_APP_AMPLIFY_API_INVENTORY;
  
const API_PATH = "/product";
const API_PATH_STOCK = "/stock";
export default class ProductService {
  getProductDetail(productId: string) {
    return axios.get(`${API_NAME}${API_PATH}/${productId}`);
  }

  getProductStockDetail(compId: string, productId: string) {
    return axios.get(
      `${API_NAME_STOCK}${API_PATH_STOCK}/list?compId=${compId}&productId=${productId}`
    );
  }
}
