
import { GappSetting } from "@geeesy/type-interfaces";
import {
  BankAccountTypeArr,
  BankList,
} from "@geeesy/type-interfaces/lib/enum-const";
import { defineComponent, PropType } from "vue";
import {
  StatusOrderCustomer,
  StatusOrderSeller,
} from "@geeesy/type-interfaces/lib/type-order";
import { IPaymentMethodTypeDeposit } from "@geeesy/type-interfaces/lib/type-payment";
import {IFormSalePage} from "@/core/services/order.service";

export default defineComponent({
  name: "compBill",
  props: {
    data: Object as PropType<IFormSalePage | null>,
    paymentData: Array as PropType<GappSetting.PaymentMethod[]>,
  },
  data() {
    return {
      envMedia: process.env.VUE_APP_S3_MEDIA_URL,
      dialogCopyArr: [],
      StatusOrderSeller: StatusOrderSeller,
      StatusOrderCustomer: StatusOrderCustomer,
    };
  },
  methods: {
    copyLink(data) {
      const copyArr = this.dialogCopyArr as Array<Boolean>;
      copyArr.push(true);
      navigator.clipboard.writeText(data.paymentMethodAttribute.accountNo);
      setTimeout(() => {
        this.dialogCopyArr.shift();
      }, 1000);
    },
    convertBankName(name) {
      return BankList[name.toLowerCase()].official_name_thai
        .split("ธนาคาร")
        .join("");
    },
    convertTypePayment(name) {
      const data = BankAccountTypeArr.find(
        (val: any) => val.value == name
      ) as any;
      return data.text;
    },
    paymentNumber(e) {
      let number = e;
      console.log(number);
      return `${number.slice(0, 3)}-${number.slice(3, 4)}-${number.slice(
        4,
        9
      )}-${number.slice(9)}`;
    },
    getAttribute(item): IPaymentMethodTypeDeposit {
      return item.paymentMethodAttribute as IPaymentMethodTypeDeposit;
    },
    mapListPaymentAvailableData(data) {
      let newDataArr: any = [];

      data.map((res: any) => {
        const find: any = newDataArr.find(
            (val: any) => val.name === res.paymentMethodType
        );

        if (find) {
          console.log({ find });

          find.data.push(res);
        } else {
          newDataArr.push({ name: res.paymentMethodType, data: [res] });
        }
      });
      console.log({ newDataArr });

      return newDataArr;
    },
  },
});
