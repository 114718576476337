import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card flex flex-col md:flex-row text-left sm:text-center items-center mb-6 py-2 px-4 xl:py-5 xl:px-6 sm:-mt-6 sm:-mx-10" }
const _hoisted_2 = { class: "flex flex-1 items-center w-full md:w-auto" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col md:flex-row text-left" }
const _hoisted_6 = {
  key: 1,
  class: "flex flex-row"
}
const _hoisted_7 = { class: "text-lg xl:text-2xl text-green-island-500 font-bold ml-2" }
const _hoisted_8 = { class: "w-full sm:w-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "w-10 h-10",
          src: require('@/assets/images/main-logo.svg'),
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.topic)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(["focus:ring-0 focus:outline-none", 
            _ctx.heading
              ? 'text-base md:text-3xl text-gray-300 font-bold  ml-2 cursor-pointer'
              : 'text-xl md:text-3xl text-gray-500 font-bold ml-2'
          ])
            }, _toDisplayString(_ctx.topic) + " /  ", 3))
          : _createCommentVNode("", true),
        (_ctx.heading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.heading) + " " + _toDisplayString(_ctx.orderId), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "default", { select: "#buttonGroup" })
    ])
  ]))
}