import {IOrderAccounting} from "@geeesy/type-interfaces/lib/type-order";
import axios from "axios";
import {GappInventory, GappSetting} from "@geeesy/type-interfaces";
import {IPersonContactInfo, ISocial} from "@geeesy/type-interfaces/lib/type-apps";
import {IAddress, OfficeHour} from "@geeesy/type-interfaces/lib/type-company";

const API_NAME = process.env.VUE_APP_AMPLIFY_API_COMMERCE;
const API_NAME_STOCK = process.env.VUE_APP_AMPLIFY_API_INVENTORY;

const API_Path = "/onlineorder";
const API_PATH_STOCK: string = "/stock";

interface IProducts extends GappInventory.Product{
    warehouse:GappInventory.ListStock
    countStock:number
    stockLevel:number
    productQty:number
    itemsPriceRow:number
    stockOrder:number
}
export interface IFormSalePage {
    accountingData: IOrderAccounting,
    expiryOnPaymentInMin: number,
    identityId: string,
    identityFullName: string,
    isVariant: boolean,
    compId: string,
    companyName: string,
    companyMarketLogoUrl: string,
    companyMarketName: string,
    businessId: string,
    products:IProducts[],
    hasNoShipment: boolean,
    paymentAvailableData: GappSetting.PaymentMethod[]
    shippingConfirmedData: GappSetting.ShippingMethod | null
    customerContactInfo:IPersonContactInfo
}

export default class OrderService {

    getSocialGroup() {
      return {
        line: "",
        facebook: "",
      } as ISocial;
    }

    getAddressGroup() {
      return {
        addrName: "",
        addrFull: "",
        addrNumber: "",
        province: "",
        amphoe: "",
        tambon: "",
        postcode: "",
        phonesExt: [""],
        phones: [""],
        mobiles: [""],
        faxes: [""],
        emails: [""],
        country: "",
        lat: "",
        lng: "",
        officeHours: [
          {
            day: "",
            times: ["", ""],
          } as OfficeHour,
        ],
        isPrimary: false,
      } as IAddress;
    }

    getDetailOnlineOrder(compId: string, orderId: string) {
        return axios.get(`${API_NAME}${API_Path}/detail/${compId}/${orderId}`);
    }

    get getEnvName() {
        const split = API_NAME.split("/")
        return split[split.length - 1] === 'beta' ? 'dev' : split[split.length - 1] === 'stg' ? 'staging' : split[split.length - 1]
    }

    updateDetailOnlineOrder(body: any) {
        return axios.put(
            `${API_NAME}${API_Path}/updatestatus/${body.compId}/${body.orderId}`,
            body
        );
    }

    getProductVariantStock(compId: string, productId: string, variantId: string) {
        return axios.get(
            `${API_NAME_STOCK}${API_PATH_STOCK}/list/?compId=${compId}&productId=${productId}&variantId=${variantId}`
        );
    }

    calAccountingData(data): IOrderAccounting {
        console.log("---START calAccountingData---")
        console.log("data => ", data)
        console.log("accountingData => ", data.accountingData)
        const AccountingObj: IOrderAccounting = {
            isDiscountOnPercent: false,
            subtotalAmount: data.accountingData.subtotalAmount,
            shippingCost: data.accountingData.shippingCost,
            charges: 0,
            discount: data.accountingData.discount,
            totalDiscountAmount: 0,
            netAmount: 0,
            vat: 0,
            grandTotalAmount: +(data.accountingData.grandTotalAmount + data.accountingData.shippingCost).toFixed(2),
            taxWithheldPercent: 0,
            withholdingTax: data.accountingData.taxWithHold,
            totalPayAmount: +(data.accountingData.grandTotalAmount + data.accountingData.shippingCost).toFixed(2),
            isVatInc: data.accountingData.isVatInc,
            vatPercentage: data.accountingData.vatPercentage
        }

        // ยอดรวมก่อนภาษี
        if (AccountingObj.isVatInc || AccountingObj.vatPercentage !== 0) {
            if (AccountingObj.isVatInc) {
                console.log('netAmount isVatInc')
                // Update Vat percent Doc cal
                // AccountingObj.vat = (AccountingObj.grandTotalAmount * 7) / 107
                data.vat = ((AccountingObj.grandTotalAmount * AccountingObj.vatPercentage) / (100 + AccountingObj.vatPercentage)).toFixed(2)
                AccountingObj.netAmount = +(AccountingObj.grandTotalAmount - AccountingObj.vat).toFixed(2)
                AccountingObj.grandTotalAmount = +(AccountingObj.grandTotalAmount).toFixed(2)
            } else {
                console.log('netAmount2 not isVatInc')
                // Update Vat percent Doc cal
                // AccountingObj.vat = (AccountingObj.grandTotalAmount * 7) / 100
                AccountingObj.vat = +((AccountingObj.grandTotalAmount * AccountingObj.vatPercentage) / 100).toFixed(2)
                AccountingObj.netAmount = +(AccountingObj.grandTotalAmount).toFixed(2)
                AccountingObj.grandTotalAmount = +(AccountingObj.netAmount + AccountingObj.vat).toFixed(2)
            }
        } else {
            console.log('netAmount3')
            AccountingObj.netAmount = 0
            AccountingObj.vat = 0
        }

        return AccountingObj
    }
}
