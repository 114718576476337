
import {defineComponent, PropType} from "vue";
import {IFormSalePage} from "@/core/services/order.service";

export default defineComponent({
  name: "compPriceSummary",
  props: {
    data: Object as PropType<IFormSalePage | null>,
  },
  methods: {
    openDialogConfirmpayment(data) {
      const res = this.$refs as any;
      res.paymentConfirm.openDialog(data);
    },
    currency(e) {
      return parseFloat(e)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
});
